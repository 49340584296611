import React from 'react';
import PropTypes from 'prop-types';

const FooterLink = ({ Icon, label, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex flex-col items-center gap-6 text-center"
    >
      <Icon />
      <span className="text-white text-sm">{label}</span>
    </a>
  );
};

FooterLink.propTypes = {
  Icon: PropTypes.node,
  label: PropTypes.string,
  url: PropTypes.string,
};

export default FooterLink;
