import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const navMenu = [
  {
    label: 'introduction',
    url: '/#introduction',
  },
  {
    label: 'articles',
    url: '/articles',
    submenu: [
      {
        label: 'entretiens',
        url: '/entretiens',
      },
      {
        label: 'critiques',
        url: '/critiques',
      },
      {
        label: 'reportages',
        url: '/reportages',
      },
      {
        label: 'divers',
        url: '/divers',
      },
    ],
  },
  {
    label: 'contact',
    url: '/#contact',
  },
];
const NavMenuMobile = ({ show, setShow, enableBodyScroll }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  return (
    <nav
      className={`hidden-lg w-100vw flex-1 bg-black z-9999 p-6 absolute left-50 top-50 -translate-50 ${show ? '' : 'hidden'}`}
    >
      <ul className="flex flex-col gap-6">
        {navMenu.map((item, index) => (
          <li
            key={item.label}
            className="flex flex-wrap justify-center relative"
          >
            <Link
              to={item.url}
              className="text-gray-400 uppercase hover-white transition-all text-xl flex items-center gap-2 relative"
              onClick={() => {
                setShow(false);
                enableBodyScroll();
              }}
            >
              {item.label}
            </Link>
            {item.submenu && (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenSubMenu((prev) => (prev === index ? null : index));
                  }}
                  className="bg-transparent grid place-items-center px-2"
                >
                  <StaticImage
                    src="../../images/icons/arrow-down.svg"
                    width={16}
                    height={16}
                  />
                </button>
              </>
            )}
            {item.submenu && openSubMenu === index && (
              <ul className="flex flex-col gap-4 w-full pt-6">
                {item.submenu.map((subMenuItem) => (
                  <li
                    key={subMenuItem.label}
                    className="flex justify-center relative"
                  >
                    <Link
                      to={item.url + subMenuItem.url}
                      className="text-gray-400 uppercase hover-white transition-all text-base"
                      onClick={() => {
                        setShow(false);
                        enableBodyScroll();
                      }}
                    >
                      {subMenuItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

NavMenuMobile.propTypes = {
  show: PropTypes.bool,
  enableBodyScroll: PropTypes.func,
  setShow: PropTypes.func,
};

export default NavMenuMobile;
