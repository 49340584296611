import React from 'react';
import FooterLink from './FooterLink';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const PageFooter = ({
  contactEmail,
  facebook,
  twitter,
  youtube,
  instagram,
  linkedin,
}) => {
  const footerLinks = [
    {
      Icon: () => (
        <StaticImage
          src="../../images/icons/fb.svg"
          layout="fixed"
          width={50}
          height={50}
        />
      ),
      label: 'Facebook',
      url: facebook,
    },
    {
      Icon: () => (
        <StaticImage
          src="../../images/icons/x.svg"
          layout="fixed"
          width={50}
          height={50}
        />
      ),
      label: 'Twitter',
      url: twitter,
    },
    {
      Icon: () => (
        <StaticImage
          src="../../images/icons/yt.svg"
          layout="fixed"
          width={50}
          height={50}
        />
      ),
      label: 'Youtube',
      url: youtube,
    },
    {
      Icon: () => (
        <StaticImage
          src="../../images/icons/ig.svg"
          layout="fixed"
          width={50}
          height={50}
        />
      ),
      label: 'Instagram',
      url: instagram,
    },
    {
      Icon: () => (
        <StaticImage
          src="../../images/icons/in.svg"
          layout="fixed"
          width={50}
          height={50}
        />
      ),
      label: 'LinkedIn',
      url: linkedin,
    },
  ];

  return (
    <div className="w-90-lg w-full mx-auto mt-auto">
      <div className="container mx-auto py-16 px-6 border-top border-white-40">
        <div className="flex justify-around mb-12">
          {footerLinks.map((item) => (
            <FooterLink key={item.label} {...item} />
          ))}
        </div>
        <span className="text-2xl-xxl text-lg text-xl-lg text-white text-center inline-block w-full">
          {contactEmail}
        </span>
      </div>
    </div>
  );
};

PageFooter.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  youtube: PropTypes.string,
  twitter: PropTypes.string,
  contactEmail: PropTypes.string,
};

export default PageFooter;
