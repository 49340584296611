import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SubMenu from './SubMenu';

const NavMenu = ({ categories }) => {
  const navMenu = [
    {
      label: 'introduction',
      url: '/#introduction',
    },
    {
      label: 'articles',
      url: '/articles',
      submenu: categories?.map((category) => ({
        label: category.attributes.name,
        url: '/' + category.attributes.slug,
      })),
    },
    {
      label: 'contact',
      url: '/#contact',
    },
  ];
  const [openSubMenu, setOpenSubMenu] = useState(null);
  return (
    <nav className="">
      <ul className="flex items-center gap-10">
        {navMenu.map((item, index) => (
          <li
            key={item.label}
            className="relative h-full"
            onMouseOver={() => setOpenSubMenu(index)}
            onMouseOut={() => setOpenSubMenu(null)}
          >
            <Link
              to={item.url}
              className="text-gray-400 uppercase hover-white transition-all"
            >
              {item.label}
            </Link>
            {item.submenu && (
              <SubMenu
                open={openSubMenu === index}
                index={index}
                baseUrl={item.url}
                list={item.submenu}
              />
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

NavMenu.propTypes = {
  categories: PropTypes.array,
};

export default NavMenu;
