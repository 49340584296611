import React from 'react';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import PropTypes from 'prop-types';

const PageLayout = ({ children, contact, categories }) => {
  return (
    <div className="flex flex-col relative w-full h-full relative min-h-100vh">
      <PageHeader categories={categories} />
      {children}
      <PageFooter {...contact} />
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  contact: PropTypes.object,
  categories: PropTypes.array,
};

export default PageLayout;
