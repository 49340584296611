import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ bgColor, children }) => {
  return (
    <span
      className="text-black uppercase font-bold text-sm inline-block min-w-105-px p-2"
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </span>
  );
};

Badge.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node,
};

export default Badge;
