import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SubMenu = ({ open, baseUrl, list }) => {
  if (!open) return null;
  return (
    <div className="py-3 absolute left-0 top-100 min-w-170-px scale-in-top">
      <ul className="bg-white">
        {list.map((item) => (
          <li
            key={item.label}
            className="border-bottom border-black-20 transition-all"
          >
            <Link
              to={`${baseUrl}${item.url}`}
              className="uppercase text-black-60 text-base py-4 px-2 inline-block w-full hover-black"
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

SubMenu.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  baseUrl: PropTypes.string,
  open: PropTypes.bool,
};

export default SubMenu;
