const { default: axios } = require('axios');

export const apiBaseUrl = 'https://content.haithemhaouel.com';
// export const apiBaseUrl = 'http://localhost:1337';

const instance = axios.create({
  baseURL: apiBaseUrl,
});

export default instance;
