import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import NavMenu from './NavMenu';
import NavMenuMobile from './NavMenuMobile';

const PageHeader = ({ categories }) => {
  const [show, setShow] = useState(false);

  const disableBodyScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      /*eslint no-undef: "warn"*/
      document.body.style.overflowY = 'hidden';
    }
  }, []);

  const enableBodyScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      /*eslint no-undef: "warn"*/
      document.body.style.overflowY = 'auto';
    }
  }, []);

  React.useEffect(() => {
    if (show) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [show, enableBodyScroll, disableBodyScroll]);

  return (
    <>
      <div className="w-90 mx-auto relative hidden flex-lg z-9999">
        <div className="container mx-auto py-8 px-6">
          <div className="flex items-center justify-between">
            <Link to="/">
              <StaticImage
                quality={100}
                src="../../images/icons/logo.svg"
                layout="fixed"
                className="max-w-full h-auto"
              />
            </Link>
            <NavMenu categories={categories} />
          </div>
        </div>
      </div>

      <div
        className={`relative w-full hidden-lg flex flex-col z-9999 bg-black ${show ? 'h-100vh' : ''}`}
      >
        <div className="flex items-center justify-between p-6">
          <Link to="/">
            <StaticImage
              quality={100}
              src="../../images/icons/logo.svg"
              layout="fixed"
              className="max-w-full h-auto"
            />
          </Link>
          <button
            type="button"
            className="bg-transparent border-0 outline-none h-28-px w-28-px relative"
            onClick={() => setShow((prev) => !prev)}
          >
            <StaticImage
              quality={100}
              src="../../images/icons/close.svg"
              layout="fixed"
              width={28}
              height={28}
              className={`${show ? 'scale-in' : 'scale-out'} absolute left-0 top-0`}
            />
            <StaticImage
              quality={100}
              src="../../images/icons/hamburger-menu.svg"
              layout="fixed"
              width={28}
              height={28}
              className={`${show ? 'scale-out' : 'scale-in'} absolute left-0 top-0`}
            />
          </button>
        </div>
        <NavMenuMobile
          show={show}
          setShow={setShow}
          enableBodyScroll={enableBodyScroll}
        />
      </div>
    </>
  );
};

PageHeader.propTypes = {
  categories: PropTypes.array,
};

export default PageHeader;
